    /*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
    /* Core CSS required for Ionic components to work properly */

    @import "~@ionic/angular/css/core.css";
    /* Basic CSS for apps built with Ionic */

    @import "~@ionic/angular/css/normalize.css";
    @import "~@ionic/angular/css/structure.css";
    @import "~@ionic/angular/css/typography.css";
    @import '~@ionic/angular/css/display.css';
    /* Optional CSS utils that can be commented out */

    // @import "~@ionic/angular/css/padding.css";
    // @import "~@ionic/angular/css/float-elements.css";
    // @import "~@ionic/angular/css/text-alignment.css";
    // @import "~@ionic/angular/css/text-transformation.css";
    // @import "~@ionic/angular/css/flex-utils.css";





    html {
        font-size: 15px;
    }

    * {
        font-family: raleway;
    }

    p {
        color: var(--ion-color-dark);
        font-family: raleway;
    }

    h1 {
        color: var(--ion-color-dark);
        font-family: ralewayBold;
    }

    h2 {
        color: var(--ion-color-dark);
        font-family: ralewayBold;
    }

    h3 {
        color: var(--ion-color-dark);
        font-family: ralewayBold;
    }

    h4 {
        color: var(--ion-color-dark);
        font-family: ralewayBold;
    }

    h5 {
        color: var(--ion-color-dark);
        font-family: ralewayBold;
    }

    ion-input {
        font-family: raleway !important;
    }

    ion-textarea {
        font-family: raleway !important;
    }

    ion-searchbar {
        .searchbar-input-container {
            input {
                font-size: 16px !important;
            }
        }
    }

    ion-label {
        font-family: raleway !important;
    }

    ion-button {
        //    font-family: ralewayBold;
        --box-shadow: none;
        --border-radius: 5px;
    }

    @media (max-width: 991px) {
        ion-backdrop {
            background-color: var(--ion-color-beige);
            opacity: 0.4;
        }
    }

    .alert-button-inner {
        color: var(--ion-color-secondary);
    }

    .actionSheetRole {
        .action-sheet-button {
            color: var(--ion-color-secondary);
        }
    }

    .icon-error {
        color: var(--ion-color-danger) !important;
    }

    .error {
        color: var(--ion-color-danger) !important;
        --color: var(--ion-color-danger) !important;
        font-family: ralewayBold !important;
    }

    .icon-success {
        color: var(--ion-color-success) !important;
    }

    .cssToast {
        font-family: ralewayBold !important;
    }

    .popover-content {
        ion-item {
            font-size: 14px;
        }
    }

    /* Correcting bug when popover has too many lines */

    .multipleSelect .alert-wrapper {
        .alert-checkbox-group {
            max-height: 360px;
        }
    }

    .defaultPopover {
        --width: 350px;

        ion-label {
            white-space: unset !important;
            overflow: auto !important;
        }
    }

    .whidePopover {
        --width: 96vw;

        ion-label {
            white-space: unset !important;
            overflow: auto !important;
        }
    }

    .mediumPopover {
        --width: 73vw;

        ion-label {
            white-space: unset !important;
            overflow: auto !important;
        }
    }

    @media (min-width: 992px) {
        .mediumPopover {
            --width: 350px;
        }

        .whidePopover {
            --width: 350px;
        }
    }

    .actionSheetRole {
        .action-sheet-title {
            font-size: 14px;
            font-weight: 600;
        }
    }

    /* General class to center with space evenly */

    .justify-content-spaceEvenly {
        justify-content: space-evenly;
    }

    /* Center the app title */

    ion-header {
        ion-title {
            img {
                margin: auto;
            }
        }
    }

    /* Close icon at the top right */

    .closeModalButton {
        font-size: 34px;
        color: var(--ion-color-primary);
    }

    /* Alert used by ion-select to display item lists in tunel */

    .multiple-select-alert {
        --width: 80vw;

        .alert-checkbox-label {
            white-space: normal;
        }
    }

    /* Buttons in tunnel */

    .validStepButton {
        width: 84%;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 40px;
        text-transform: uppercase;
        font-size: 14px;
        font-family: ralewayBold !important;
        letter-spacing: 0.1rem;
    }

    /* alert button to choose between recruteur and candidats */

    .alertButtons {
        font-weight: 700 !important;
        background-color: red;
    }

    .buttonSpinner {
        margin-left: 5px;
    }

    ion-searchbar.md {
        --box-shadow: none;
    }

    .hideMe {
        display: none;
    }

    .capitalize {
        text-transform: capitalize;
    }

    /* PWA */

    .closeModalButton {
        cursor: pointer;
    }

    app-pwa-header-login {
        order: -9;
    }

    ion-split-pane {
        background-color: var(--ion-color-blanc);
        position: relative;
        height: 100%;

        .menu--split-pane {
            --background: #F5F5F8;

            ion-content {
                --background: #F5F5F8;

                ion-item {
                    --background: #F5F5F8;
                }
            }
        }
    }

    @media (min-width: 1536px) {
        ion-split-pane {
            --side-max-width: 430px;
        }
    }

    ion-select-popover {
        ion-list {
            overflow-y: scroll;
        }
    }

    .boldify {
        font-family: ralewayBold;
    }

    /* START : NEEDED FOR SERVEUR SIDE RENDERING */

    .body--isServer {

        app-home {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            position: absolute;
            flex-direction: column;
            justify-content: space-between;
            contain: layout size style;
            overflow: hidden;
            z-index: 0;
        }

        .swiper-wrapper {
            overflow: scroll;
        }

        ion-slide {
            width: auto;
        }

        ion-header {
            width: 100vw;
        }

        ion-split-pane {
            position: relative;
        }

        ion-split-pane {
            ion-router-outlet {
                width: 100%;
            }
        }

        .icon-inner {
            display: none;
        }

        ion-button {
            a {
                span {
                    font-family: ralewayBold;
                }
            }
        }

        @media (max-width:'991px') {
            .menu--split-pane {
                display: none;
            }
        }
    }

    #axeptio_main_button {
        left: 3px !important;
        bottom: 40px !important;
        opacity: 0.8;
    }


    @media (max-width:'991px') {
        ion-modal {
            .ion-header__pwa-modal {
                margin-top: 70px;
            }
        }
    }

    /* Fix support Chrome 114 */

    // [popover].popover-viewport {
    //     display: initial;
    //     position: initial;
    //     margin: initial;
    //     border: initial;
    //     background: initial;
    //     padding: initial;
    //     width: initial;
    //     height: initial;
    //     overflow: initial;
    //     inset: initial;
    //     color: initial;
    // }

    /* Montée version Ionic 7 */
    ion-header {
        ion-toolbar {
            --background: white;
            --opacity: 1 !important;

            --min-height: 56px;
        }
    }


    .ion-no-margin {
        margin: 0px;
    }

    .ion-no-padding {
        padding: 0px;
    }

    .ion-text-center {
        text-align: center !important;
    }

    .ion-justify-content-end {
        justify-content: flex-end !important;
    }

    .ion-justify-content-center {
        justify-content: center !important;
    }

    ion-card {
        contain: layout !important;
        margin: 10px;
    }

    ion-select {
        min-height: 40px;
        --padding-top: 10px;
        --padding-end: 0;
        --padding-bottom: 10px;
        --padding-start: 16px;
    }

    ion-searchbar {
        height: 46px !important;
    }

    ion-card-header {
        flex-direction: column;
    }


    ion-footer {
        background-color: var(--ion-color-blanc);
        -webkit-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.13);
        -moz-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.13);
        box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.13);
    }

    ion-toggle {
        padding-left: 10px;
        padding-right: 10px;
        height: 22px;
        --handle-width: 22px;
        --handle-height: 22px;
        --handle-max-height: auto;
        --handle-spacing: 0px;
        --handle-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }

    ion-toggle::part(track) {
        height: 16px;
        width: 42px;
        overflow: visible;
    }

    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }

    .wrap {
        white-space: normal;
    }

    .pointer {
        cursor: pointer;
    }

    .fbk-alert button.alert-button {
        font-weight: bold;
    }

    .ion-alert-danger {
        background-color: #b31d12ed !important;
    }

    .error-alert .alert-wrapper {
        h2 {
            font-family: sans-serif;
        }
    }

    .link {
        text-decoration: underline;
        color: var(--ion-color-secondary);
        cursor: pointer;
    }

    @media (min-width: 991px) {
        .big-modal {
            --width: 65vw;
            /* Largeur de la modal */
            --height: 92vh;
        }

        .biger-modal {
            --width: 50vw;
            /* Largeur de la modal */
            --height: 85vh;
            /* Hauteur de la modal */
            //  --max-width: 900px;
        }
    }