// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@font-face {
    font-family: raleway;
    src: url('../assets/fonts/raleway.woff2') format('woff2');
    font-display: swap;
}

@font-face {
    font-family: ralewayBold;
    src: url('../assets/fonts/ralewaybold.woff2') format('woff2');
    font-display: swap;
}


/** Ionic CSS Variables **/

:root {
    --ion-color-primary: #38be6b;
    --ion-color-primary-rgb: 56, 190, 107;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #31a75e;
    --ion-color-primary-tint: #4cc57a;
    --ion-color-secondary: #20A3E1;
    --ion-color-secondary-rgb: 32, 163, 225;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #1c8fc6;
    --ion-color-secondary-tint: #36ace4;
    --ion-color-tertiary: #6d2f97;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #31a75e;
    --ion-color-tertiary-tint: #4cc57a;
    --ion-color-success: #40D87A;
    --ion-color-success-rgb: 64, 216, 122;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #38be6b;
    --ion-color-success-tint: #53dc87;
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;
    --ion-color-danger: #b31d12;
    --ion-color-danger-rgb: 179, 29, 18;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #9e1a10;
    --ion-color-danger-tint: #bb342a;
    --ion-color-dark: #484848;
    --ion-color-dark-rgb: 72, 72, 72;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #3f3f3f;
    --ion-color-dark-tint: #5a5a5a;
    --ion-color-medium: #9C9EA1;
    --ion-color-medium-rgb: 156, 158, 161;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #898b8e;
    --ion-color-medium-tint: #a6a8aa;
    --ion-color-mediumLight: #d1d1d1;
    --ion-color-mediumLight-rgb: 209, 209, 209;
    --ion-color-mediumLight-contrast: #000000;
    --ion-color-mediumLight-contrast-rgb: 0, 0, 0;
    --ion-color-mediumLight-shade: #b8b8b8;
    --ion-color-mediumLight-tint: #d6d6d6;
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #484848;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    --ion-color-blanc: #ffffff;
    --ion-color-blanc-rgb: #ffffff;
    --ion-color-blanc-contrast: #ffffff;
    --ion-color-blanc-contrast-rgb: #ffffff;
    --ion-color-blanc-shade: #ffffff;
    --ion-color-blanc-tint: #ffffff;
    --ion-color-noir: #000;
    --ion-color-noir-rgb: #000;
    --ion-color-noir-contrast: #000;
    --ion-color-noir-contrast-rgb: 0, 0, 0;
    --ion-color-noir-shade: #000;
    --ion-color-noir-tint: #000;
    --ion-color-beige: #F0EFEA;
    --ion-color-beige-rgb: 240, 239, 234;
    --ion-color-beige-contrast: #000000;
    --ion-color-beige-contrast-rgb: 0, 0, 0;
    --ion-color-beige-shade: #d3d2ce;
    --ion-color-beige-tint: #f2f1ec;
}

ion-header {
    ion-title {
        font-size: 16px;
        padding-left: 58px;
        padding-right: 58px;
    }

    @media (max-width:414px) {
        ion-title {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}

// html.plt-mobileweb.ios {
//     height: 101vh;
//     max-height: none;
//     overflow-y: scroll;
// }